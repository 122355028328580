import React from 'react'
import PropTypes from 'prop-types'
import './loading.css'

export default class Loading extends React.Component{
  render(){
    return(
      <div className="loading-box">
        <div className="loading"></div>
        <p className="loading-text">Loading</p>
        {/* <p className="loading-text">{this.props.text === undefined ? "loading..." : this.props.text}</p> */}
      </div>
    )
  }
}

Loading.propTypes = {
  text  : PropTypes.string
}