import React from "react";
import {Route} from 'react-router-dom'
import PrivateRoute from "./privateroute";
import { NavigateParams } from "./utils";

export interface RouteConfig{
  path?     : string,
  element?  : React.LazyExoticComponent<any>,
  index?    : boolean,
  isPrivate?: boolean,
  child?    : Array<RouteConfig>
}

export default class RouteParser{
  static parseRoute = (config : Array<RouteConfig>, loggedIn : boolean) => {
    return config.map((routeConf, index) => {
      if(routeConf.child){
        const {element, isPrivate, ...props} = routeConf
        if(element === undefined && isPrivate === undefined){
          return(
            <Route {...props} key={`route_${index}`}>
              {RouteParser.parseRoute(routeConf.child, loggedIn)}
            </Route>
          )
        }
        else if(isPrivate !== undefined && element != undefined){
          const Element = element
          return (
            <Route {...props} key={`route_${index}`} element = {
              <NavigateParams>
                <PrivateRoute login = {loggedIn} element = {<Element />}/>
              </NavigateParams>
            }>
              {RouteParser.parseRoute(routeConf.child, loggedIn)}
            </Route>
          )
        }
        else if(isPrivate === undefined && element != undefined){
          const Element = element
          return (
            <Route {...props} key={`route_${index}`} element = {
              <NavigateParams>
                <Element />
              </NavigateParams>
            }>
              {RouteParser.parseRoute(routeConf.child, loggedIn)}
            </Route>
          )
        }
      }
      else{
        const {element, isPrivate, ...props} = routeConf
        const Element = element
        return isPrivate === undefined ?
          <Route {...props} key={`route_${index}`} element = {
            <NavigateParams>
              <Element />
            </NavigateParams>
          } /> :
          <Route {...props} key={`route_${index}`} element = {
            <NavigateParams>
              <PrivateRoute login = {loggedIn} element = {<Element />} />
            </NavigateParams>
          } />
      }
    })
  }
}
