import TitleText from 'component/titletext/titletext'
import React from 'react'
import { Outlet } from 'react-router-dom'
import "../dashboard/dashboard.css"
import "../dashboard/header/header.css"
import "../dashboard/sidebar/sidebar.css"

export default class Development extends React.Component{
  render(){
    return(
      <div className = 'development'>
        <TitleText titleTextFirst = 'DEVELOPMENT' titleTextLast = 'DEVELOP'/>
        <main className = "pharma-content simulation-content">
          <Outlet />
        </main>
      </div>
    )
  }
}