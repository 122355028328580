import Development from './main'
import React from 'react'

const DevRoutes = 
[{
  path    : '/dev',
  element : Development,
  child   : [{
    index : true,
    element : React.lazy(() => import('pages/dev/main'))
  }]
}]

export default DevRoutes
