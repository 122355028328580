import React from "react";
import Loading from 'core/loading/loading'
import { Navigate } from "react-router-dom";

export default class PrivateRoute extends React.Component{
  static LoadingText = 'Authenticating'
  constructor(props){
    super(props)
    this.verifyProps(props)
  }
  verifyProps(props){
    if(props.element === undefined) throw Error('element prop have to be given')
    if(props.login === undefined) throw Error('login prop have to be given')
  }
  navigateIfNotLoggedIn(){
    const to  = window.location.href
    .split('/')
    .slice(3)
    .join('/')
    .replace(/\//g, "_slash_")
    return`/login/cont_to=${to}`
  }
  render(){
    const login   = this.props.login
    if(login === null) 
      return <Loading text = {PrivateRoute.LoadingText} />
    else if (login === true){
      const element   = this.props.element
      return React.cloneElement(element, {...element.props})
    }
    else return <Navigate to = {this.navigateIfNotLoggedIn()}/>
  }
}