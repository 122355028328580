import React, { useState, useEffect } from 'react';
import { NotificationType } from './utils';
import { NotificationContext } from './utils';
import { UNIVERSAL_TOKEN } from 'API/base';

const NotificationProvider: React.FC<React.PropsWithChildren> = ({children}) => {

  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [isReady, setIsReady] = useState(false);
  const [ws, setWs] = useState<WebSocket | null>(null);

  useEffect(() => {
    const ws = new WebSocket(`${process.env.REACT_APP_WS_ENDPOINT}notification/`);
    setWs(ws);
    ws.onopen = (event) =>{
      ws.send(JSON.stringify({ jwt:  UNIVERSAL_TOKEN.jwtToken}))
    }
    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data)
        if(data.type === 'notification.initial'){
          const unread = data.msg.unread
          const read = data.msg.read
          setNotifications([ ...unread, ...read ])
          setIsReady(true)
        }
        else if(data.type === 'notification.update'){
          const msg : NotificationType = data.msg
          setNotifications((notifications) => [msg, ...notifications]);
        }
      } catch (error) {
        console.error('Failed to parse notification', error);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket is closed');
    };

    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, []);

  const markAsread = React.useCallback((message: NotificationType) => {
    if (message.read) return;
    setNotifications(notifications => {
      const msgId = notifications.findIndex(item => item.id === message.id)
      if (msgId !== -1){
        const updatedNotifications = notifications.slice()
        const notification = updatedNotifications[msgId]
        updatedNotifications[msgId] = { ...notification, read : true }
        return updatedNotifications
      }
      return notifications
    });
    const sendData = {
      type: "notification.read",
      msg: { id: message.id }
    }
    if(ws) ws.send(JSON.stringify(sendData))
  }, [setNotifications, ws])
  const reSort = React.useCallback(() => {
    setNotifications(notifications =>
      notifications.sort(
        (a, b) => (
          ((a.read ? 1 : -1) - ( b.read ? 1 : -1)) ||
          (new Date(b.date).getTime() - new Date(a.date).getTime())
        )
      ).slice()
    )
  }, [setNotifications])

  const context = React.useMemo(() => {
    return {
      notifications: notifications,
      markAsread,
      reSort,
      isReady
    }
  }, [notifications, markAsread])

  return (
    <NotificationContext.Provider value = {context}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider





