import { createRoot } from "react-dom/client";
import * as RDKit from '@rdkit/rdkit'
import App from "./App";

declare global{
  interface Window{
    RDKit: RDKit.RDKitModule
  }
}
window.initRDKitModule()
.then((RDKit) => {
  window.RDKit = RDKit
  const rootElement = document.getElementById('root')
  if (rootElement !== null){
    const root = createRoot(rootElement);
    root.render(<App />);
  }
})
