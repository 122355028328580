import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import RouteParser from "./parser";
import { UserContext } from "./utils";
import MainRoutes from "routes/main/conf";
import DashboardRoutes from "routes/dashboard/conf";
import Loading from 'core/loading/loading'
import UserAPI from "API/user";
import './routes.css'
import DevRoutes from "./dev/conf";
import NotificationProvider from "./notification";

const NotFound = React.lazy(() => import('core/not-found/not-found'))

export default class Routing extends React.Component{
  constructor(props){
    super(props)
    this.state  = {
      user       : this.loadUser(),
      setUser    : this.setUser,
      isLoggedIn : null
    }
  }
  componentDidMount(){
    this.updateLoginStatus()
  }
  componentDidUpdate(prevProps, prevState){
    if(prevState.user !== this.state.user) this.updateLoginStatus()
  }
  loadUser = () => {
    let user  = null
    try{
      user  = UserAPI.loadFromLocal()
    }
    catch(err){
      // console.error(err)
      console.warn("No user data. Restoring default")
    }
    return user
  }
  setUser = (newUser) => {
    return new Promise((res, rej) => {
      this.setState({user : newUser}, () => res())
    })
  }
  updateLoginStatus(){
    let user = this.state.user
    if(user === null)
      this.setState({isLoggedIn : false})
    else{
      user.checkLogin()
      .then(() => this.setState({isLoggedIn : true}))
      .catch(() => this.setState({isLoggedIn : false}))
    }
  }
  render(){
    const isLoggedIn  = this.state.isLoggedIn
    const isDebug = process.env.REACT_APP_DEBUG==="true"
    return(
      <Router>
        <React.Suspense fallback = {<Loading text = 'Loading' />}>
          <UserContext.Provider value = {this.state}>
            <NotificationProvider>
              <Routes>
                {RouteParser.parseRoute(MainRoutes, isLoggedIn)}
                {RouteParser.parseRoute(DashboardRoutes, isLoggedIn)}
                {/* {isDebug && <Route path = 'test-page' element = {<TestElem />}/>} */}
                {isDebug && RouteParser.parseRoute(DevRoutes, isLoggedIn)}˚
                <Route path = '*' element = {<NotFound />}/>
              </Routes>
            </NotificationProvider>
          </UserContext.Provider>
        </React.Suspense>
      </Router>
    )
  }
}
