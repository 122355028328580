import React from "react"

const MainRoutes = [{
  path    : '',
  element : React.lazy(() => import('routes/main/main')),
  child   : [{
    index   : true,
    element : React.lazy(() => import('pages/main/main'))
  }, {
    path    : 'login',
    child   : [{
      index   : true,
      element : React.lazy(() => import('pages/login/main'))
    }, {
      path    : 'cont_to=:cont_to',
      element : React.lazy(() => import('pages/login/main'))
    }]
  }, {
    path    : 'register',
    element : React.lazy(() => import('pages/register/main'))
  }, {
    path    : 'active',
    element : React.lazy(() => import('pages/register/activate'))
  }
  , {
    path    : 'forget-request',
    element : React.lazy(() => import('pages/forget-request/main'))
  }, {
    path    : 'forget-change/:token',
    element : React.lazy(() => import('pages/forget-change/main'))
  }, {
    path    : 'terms-of-service',
    element : React.lazy(() => import('pages/terms-of-service/main')),
  }, {
    path    : 'terms-of-service-en',
    element : React.lazy(() => import('pages/terms-of-service/main-en')),
  }, {
    path    : 'privacy-policy',
    element : React.lazy(() => import('pages/privacy-policy/main'))
  },  {
    path    : 'privacy-policy-en',
    element : React.lazy(() => import('pages/privacy-policy/main-en'))
  },{
    path    : 'service',
    child   : [{
      path    : 'about',
      element : React.lazy(() => import('pages/service/about/main'))
    }, {
      path    : 'how-to-use',
      element : React.lazy(() => import('pages/service/how-to-use/main'))
    }, {
      path    : 'membership',
      element : React.lazy(() => import('pages/service/membership/main'))
    }, {
      path    : 'dedicated-server',
      element : React.lazy(() => import('pages/service/dedicated-server/main'))
    }]
  }, {
    path    : 'news',
    child   : [{
      path    : 'notice',
        child:[{
          index   : true,
          element : React.lazy(() => import('pages/news/notice/main'))
        },
        {
          path    : ':noticeId',
          element : React.lazy(() => import('pages/news/view'))
        }
      ],
    }, {
      path    : 'magazine',
      child:[{
        index   : true,
        element : React.lazy(() => import('pages/news/magazine/main'))
      },
      {
        path    : ':magazineId',
        element : React.lazy(() => import('pages/news/view'))
      }
    ]
    }, {
      path    : 'news-view',
      element : React.lazy(() => import('pages/news/view'))
    }, {
      path    : 'review',
      element : React.lazy(() => import('pages/news/review/main'))
    }, {
      path    : 'event',
      element : React.lazy(() => import('pages/news/event/main'))
    }]
  }, {
    path      : 'help',
    child     : [{
      path      : 'faq',
      child: [{
        index   : true,
        element : React.lazy(() => import('pages/help/faq/main'))
      }]
    }, {
      path      : 'qna',
      child: [{
        child:[{
          index   : true,
          element : React.lazy(() => import('pages/help/qna/main'))
        },
        {
          path    : 'ask',
          element : React.lazy(() => import('pages/help/qna/create-question'))
        },
        {
          path    : ':questionId',
          element : React.lazy(() => import('pages/help/qna/view'))
        }
      ],
      },{
        path    : 'qna-view',
        element : React.lazy(() => import('pages/help/qna/view'))
      }]
    }]
  }]
}, {
  path    : 'maintenance',
  element : React.lazy(() => import('pages/maintenance/main'))
}]

export default MainRoutes
