import React from 'react'
import PropTypes from 'prop-types'
import './titletext.css'

interface TitleTextProps{
  titleTextFirst  : string,
  titleTextLast   : string
}

export default class TitleText extends React.Component<TitleTextProps, any>{
  render(){
    const className = this.props.className ? this.props.className : ''
    return(
      <div className = {`title-text-container ${className}`}>
        <p className = 'title-text-first'> {this.props.titleTextFirst} </p>
        <p className = 'title-text-last'> {this.props.titleTextLast} </p>
      </div>
    )
  }
}

TitleText.propTypes = {
  titleTextFirst : PropTypes.string,
  titleTextLast : PropTypes.string,
  className : PropTypes.string
}
