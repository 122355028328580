import Routing from './routes/routes'
import React from 'react'
import "./stylesheet/index.css"
import "./fonts/fonts.css"

export default class App extends React.Component{
  render(){
    return (
      <Routing />
    )
  }
}
